type SectionTitleProps = {
  className?: string;
  title: string;
};

export function SectionTitle(props: SectionTitleProps) {
  const { className, title } = props;

  return (
    <p
      className={`text-header6 md:text-2header4 text-center font-MuseoSans100 font-bold mt-4 ${className}`}
    >
      {title}
    </p>
  );
}

export default SectionTitle;
