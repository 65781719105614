import Image from 'next/image';

import { MediaQuery, useMediaQuery } from '../../../hooks/useMediaQuery';
import { ContentType } from '../../../types/content';
import MarginSpacing from '../../atoms/margin-spacing/margin-spacing';
import SectionTitle from '../../atoms/section-title/section-title';

export function CategoryCardsHeader(props: ContentType) {
  const { description, iconHeight = 40, iconWidth = 40, src, title } = props;

  const isMobile = useMediaQuery(MediaQuery.SM);

  const splitDescription = description?.split('.');
  const [firstLine, secondLine] = splitDescription as unknown as string[];

  const newDescription = isMobile ? (
    <>
      <p>{firstLine}.</p>
      <p>{secondLine}</p>
    </>
  ) : (
    description
  );

  return (
    <div className="flex flex-col items-center justify-center font-MuseoSans100 tracking-tight text-center px-4">
      <Image
        alt="category-cards-header-image"
        height={iconHeight}
        src={src as string}
        width={iconWidth}
      />
      <MarginSpacing margin="mt-4" />
      <SectionTitle title={title} />
      <p className="text-subtitle md:text-body mt-2 font-extralight leading-4">
        {newDescription}
      </p>
    </div>
  );
}

export default CategoryCardsHeader;
