import { ImageHoverType } from '../../../types/image-hover';
import { MediaQuery, useMediaQuery } from '../../../hooks/useMediaQuery';
import ImageHover from '../image-hover/image-hover';
import * as S from './styles';

type ImageHoverDividerProps = {
  imageHoverContent: ImageHoverType[];
};

export function ImageHoverDivider(props: ImageHoverDividerProps) {
  const { imageHoverContent } = props;
  const [leftImageHoverContent, rightImageHoverContent] = imageHoverContent;

  const isMobile = useMediaQuery(MediaQuery.LG);

  const imageContent = isMobile ? (
    <ImageHover {...leftImageHoverContent} />
  ) : (
    <>
      <ImageHover {...leftImageHoverContent} />
      <ImageHover {...rightImageHoverContent} />
    </>
  );

  return (
    <S.Container className="h-60 lg:h-112 grid grid-cols-1 lg:grid-cols-2 font-MuseoSans100 relative z-10">
      {imageContent}
    </S.Container>
  );
}

export default ImageHoverDivider;
