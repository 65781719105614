import { useCallback, useContext, useRef } from 'react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { isEmpty, isEqual } from 'lodash';

import { MediaQuery, useMediaQuery } from '../../../hooks/useMediaQuery';
import ResponsiveImage from '../../atoms/responsive-image/responsive-image';
import { linkRoutes } from '../../../config/routes/links';
import CustomButton from '../../atoms/custom-button/custom-button';
import { DispatchEvents } from '../../../enums/dispatchEvent';
import { AppContext } from '../../../context/App/context';
import { autoplayProps } from '../../../config/carousel';
import { BannerWithCarouselModel } from '../../../types/banner-with-carousel';
import * as S from './styles';

type BannerCarouselProps = {
  carouselData: BannerWithCarouselModel[];
};

export function BannerCarousel(props: BannerCarouselProps) {
  const { carouselData } = props;

  const { state, dispatch } = useContext(AppContext);
  const sliderRef = useRef() as any;
  const isMobile = useMediaQuery(MediaQuery.SM);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const navigation = (
    <div className="text-header4 text-slate-800 font-light">
      <BsChevronLeft
        className="absolute top-2/4 -translate-y-2/4 left-2 lg:left-14 z-50 hover:text-black cursor-pointer"
        onClick={handlePrev}
      />
      <BsChevronRight
        className="absolute right-2 lg:right-14 top-2/4 -translate-y-2/4 z-50 hover:text-black cursor-pointer"
        onClick={handleNext}
      />
    </div>
  );

  function openCalendly() {
    dispatch({
      type: DispatchEvents.DISPLAY_CALENDLY,
      payload: {
        isOpen: true,
        url: linkRoutes.CALENDLY
      }
    });
  }

  const slides = carouselData.map((carouselItem, i) => {
    const { bgImage, ctaLabel, ctaLink, description, title, bgImageMobile } =
      carouselItem;

    const shouldOpenInNewTab =
      !isEqual(ctaLink, linkRoutes.INSPIRATION_AND_PLANNING_ARTICLE) &&
      !isEqual(ctaLink, linkRoutes.REBATE_PROGRAM)
        ? '_blank'
        : '_self';

    let descriptionTop;
    let descriptionBottom;
    if (description.includes('one-of-a-kind')) {
      descriptionTop = description.slice(
        0,
        description.indexOf('one-of-a-kind')
      );
      descriptionBottom = description.slice(
        description.indexOf('one-of-a-kind'),
        -1
      );
    }

    const descriptionContent =
      description.includes('one-of-a-kind') && isMobile ? (
        <>
          <p className="text-caption sm:text-body lg:text-header6 font-MuseoSans100 font-extralight px-10">
            {descriptionTop}
          </p>
          <p className="text-caption sm:text-body lg:text-header6 font-MuseoSans100 font-extralight px-10 mb-3">
            {descriptionBottom}
          </p>
        </>
      ) : (
        <p className="text-caption sm:text-body lg:text-header6 font-MuseoSans100 font-extralight px-10 mb-3">
          {description}
        </p>
      );

    const calendlyHandler = () => isEmpty(ctaLink) && openCalendly();

    return (
      <SwiperSlide key={ctaLink}>
        <div className="relative">
          <div className="hidden sm:block">{navigation}</div>
          <ResponsiveImage
            alt="carousel image"
            blurImageSrc="/icons/blur-placeholder.png"
            dimensions="w-full h-112 lg:h-[calc(100vh-87px)]"
            objectFit="cover"
            objectPosition={i === 0 ? 'right top' : i === 1 ? 'top' : 'center'}
            priority
            src={isMobile && bgImageMobile ? bgImageMobile : bgImage}
          />
          <S.TextContainer>
            <p className="text-header6 sm:text-header5 lg:text-header4 font-MuseoSans100 font-light">
              {title}
            </p>
            {descriptionContent}
            <CustomButton
              buttonStyle="bg-primaryGray hover:bg-widgetGray"
              buttonText={ctaLabel as string}
              ctaLink={ctaLink}
              hasLink={!!ctaLink}
              target={shouldOpenInNewTab}
              textStyle="group-hover:border-secondaryBlack group-hover:text-secondaryBlack"
              handleClickButton={calendlyHandler}
            />
          </S.TextContainer>
        </div>
      </SwiperSlide>
    );
  });

  return (
    <S.SwiperContainer>
      <Swiper
        autoplay={autoplayProps}
        className="mySwiper"
        loop={true}
        modules={[Autoplay, Navigation]}
        onInit={(core: SwiperCore) => {
          sliderRef.current = core.el;
        }}
        pagination={{ clickable: true }}
        speed={900}
      >
        {slides}
      </Swiper>
    </S.SwiperContainer>
  );
}

export default BannerCarousel;
