import Link from 'next/link';

import { splittedString } from '../../../utils/stringUtils';
import ResponsiveImage from '../../atoms/responsive-image/responsive-image';
import { CATEGORY_DESCRIPTION_MAX_LENGTH } from '../../../constants/category';
import * as S from './styles';
import { ProductCategoryModel } from '../../../interface/products';
import { brandColors } from '../../../constants/colors';
import { SUBZERO_SLUG } from '../../../constants/brands';

/* eslint-disable-next-line */
type CategoryCardProps = {
  categoryCardContent: ProductCategoryModel;
};

export function CategoryCard(props: CategoryCardProps) {
  const { categoryTitle, categoryDescription, categoryImage, categoryLink } =
    props.categoryCardContent;

  const displayDescription = splittedString(
    categoryDescription,
    CATEGORY_DESCRIPTION_MAX_LENGTH
  );

  const hoverColor = categoryLink.includes(SUBZERO_SLUG)
    ? brandColors.SUBZERO_HEX
    : brandColors.WOLF_HEX;

  return (
    <S.Container hoverColor={hoverColor as string}>
      <div className="h-60">
        <ResponsiveImage
          alt="Category Image"
          blurImageSrc="/icons/blur-placeholder.png"
          dimensions="w-full h-full"
          objectFit="cover"
          src={categoryImage.data.attributes.url}
        />
      </div>

      <div className="flex flex-col p-4 hover:text-white group h-60 card-content bg-widgetGray">
        <p className="uppercase text-header6 text-primaryGray font-MuseoSans300 group-hover:text-white leading-6">
          {categoryTitle}
        </p>
        <p className="pt-2 text-subtitle">{displayDescription}</p>
        <div className="flex justify-end pt-4 cursor-pointer mt-auto">
          <span className="border-y py-2 border-black group-hover:border-white transition-all duration-200 ease-linear">
            <Link href={categoryLink} passHref>
              <a className="px-3 font-MuseoSans100 text-caption">
                FIND OUT MORE &gt;
              </a>
            </Link>
          </span>
        </div>
      </div>
    </S.Container>
  );
}

export default CategoryCard;
