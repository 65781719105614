import styled from 'styled-components';

import { brandColors } from '../../../constants/colors';

const { SUBZERO_HEX, WOLF_HEX } = brandColors;

type SwiperContainerProps = {
  shouldDisplayPagination?: boolean;
  paginationColor: typeof SUBZERO_HEX | typeof WOLF_HEX;
};

export const SwiperContainer = styled.div.attrs({
  className: 'relative'
})<SwiperContainerProps>`
  .categoryCards .swiper-pagination {
    display: ${({ shouldDisplayPagination }) =>
      shouldDisplayPagination ? 'flex' : 'none'};
    justify-content: center;
    text-align: center;
    width: 100% !important;
    position: absolute;
    bottom: -60px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .categoryCards .swiper-pagination-bullet {
    cursor: pointer;
    border-radius: 50%;
    margin: 5px;
    width: 12px;
    height: 12px;
    background-color: #eeeeee;
    opacity: 1;
  }

  .categoryCards .swiper-pagination-bullet-active {
    background-color: ${(props) => props.paginationColor};
  }
`;
