import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useCallback, useRef } from 'react';

import { useMediaQuery, MediaQuery } from '../../../hooks/useMediaQuery';
import {
  oneAndOneFifthSlidesPerView,
  threeSlidesPerView
} from '../../../constants/modals';
import { getSlidesPerView } from '../../../utils/carousel';
import { autoplayProps } from '../../../config/carousel';
import { brandColors } from '../../../constants/colors';
import CategoryCard from '../category-card/category-card';
import * as S from './styles';
import { ProductCategoryModel } from '../../../interface/products';

/* eslint-disable-next-line */
type CategoryCardsProps = {
  categoryCardsContent: ProductCategoryModel[];
  paginationColor: typeof brandColors.SUBZERO_HEX | typeof brandColors.WOLF_HEX;
};

export function CategoryCards(props: CategoryCardsProps) {
  const { categoryCardsContent, paginationColor } = props;

  const isTablet = useMediaQuery(MediaQuery.MD);

  const sliderRef = useRef() as any;

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const slidesPerGroup = isTablet ? 1 : 3;
  const slidesPerView = getSlidesPerView(
    oneAndOneFifthSlidesPerView,
    threeSlidesPerView,
    isTablet
  );

  const navigation = (
    <div className="hidden xl:block">
      <BsChevronLeft
        className="absolute -left-12 top-2/4 -translate-y-2/4 text-header5 text-secondaryBlack z-20 cursor-pointer"
        style={{ color: paginationColor }}
        onClick={handlePrev}
      />
      <BsChevronRight
        className="absolute -right-12 top-2/4 -translate-y-2/4 text-header5 text-secondaryBlack z-20 cursor-pointer"
        style={{ color: paginationColor }}
        onClick={handleNext}
      />
    </div>
  );

  const slides = categoryCardsContent.map((categoryCard) => {
    return (
      <SwiperSlide key={categoryCard.categoryTitle}>
        <CategoryCard categoryCardContent={categoryCard} />
      </SwiperSlide>
    );
  });

  return (
    <S.SwiperContainer
      paginationColor={paginationColor}
      shouldDisplayPagination={!isTablet}
    >
      {navigation}
      <Swiper
        autoplay={autoplayProps}
        centeredSlides={true}
        className="categoryCards"
        loop={true}
        modules={[Autoplay, Pagination]}
        onInit={(core: SwiperCore) => {
          sliderRef.current = core.el;
        }}
        pagination={{
          clickable: true
        }}
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerGroup}
        spaceBetween={20}
        speed={900}
        style={{ position: 'unset' }}
      >
        {slides}
      </Swiper>
    </S.SwiperContainer>
  );
}

export default CategoryCards;
