import { isEqual } from 'lodash';

import ResponsiveImage from '../../atoms/responsive-image/responsive-image';

/* eslint-disable-next-line */
type ImageHoverProps = {
  alt: string;
  hoverTextTop: string;
  hoverTextBottom: string;
  src: string;
};

export function ImageHover(props: ImageHoverProps) {
  const { alt, hoverTextTop, hoverTextBottom, src } = props;

  const hoverTextAlignment = isEqual(hoverTextTop, 'FRESHNESS')
    ? 'text-center'
    : '!text-right';

  return (
    <div className="image-hover relative">
      <ResponsiveImage
        alt={alt}
        blurImageSrc="/icons/blur-placeholder.png"
        dimensions="w-full h-full"
        objectFit="cover"
        objectPosition="25% 30%"
        src={src}
      />
      <div
        className={`hover-text text-header4 font-extralight flex flex-col ${hoverTextAlignment}`}
      >
        <p>{hoverTextTop}</p>
        <p>{hoverTextBottom}</p>
      </div>
    </div>
  );
}

export default ImageHover;
