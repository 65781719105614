import styled from 'styled-components';

type ContainerProps = {
  hoverColor: string;
};

export const Container = styled.div.attrs({
  className: 'flex flex-col w-full h-[480px] hover:opacity-90'
})<ContainerProps>`
  .card-content:hover {
    background: ${(props) => props.hoverColor};
  }
`;
