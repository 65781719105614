import styled from 'styled-components';

export const Container = styled.div`
  .hover-text {
    position: absolute;
    bottom: -20%;
    right: 10%;
    transform: translate(10%, 10%);
    opacity: 0;
    transition: all 1s ease-in-out;
    text-align: center;
    color: white;
  }

  div:hover .hover-text {
    z-index: 51;
    opacity: 1;
    bottom: 10%;
    right: 10%;
    transform: translate(10%, 10%);
  }

  div:hover.image-hover::after {
    z-index: 50;
    opacity: 0.1;
  }

  .image-hover::after {
    content: '';
    height: 100%;
    width: 100%;
    background: white;
    position: absolute;
    top: 0;
    bottom: 0;
  }
`;
