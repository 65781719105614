export function ensureToString(value: string | undefined): string {
  return value ?? '';
}

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertToSnakeCase(string: string) {
  return string.toLowerCase().split(' ').join('-');
}

export function splittedString(string: string, limit: number) {
  const text = string.substring(0, limit).split(' ');
  text.pop();
  const joinedString = text.join(' ');
  const truncatedText = string.length > limit ? `${joinedString}...` : string;

  return truncatedText;
}
