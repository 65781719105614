import CategoryCards from '../components/molecules/category-cards/category-cards';
import CategoryCardsHeader from '../components/molecules/category-cards-header/category-cards-header';
import ImageHoverDivider from '../components/molecules/image-hover-divider/image-hover-divider';
import {
  getImageHoverContent,
  getHomepageCarouselContent,
  getSubzeroCategoryCardsHeaderContent,
  getWolfCategoryCardsHeaderContent
} from '../services/home';
import { ContentType } from '../types/content';
import { ImageHoverType } from '../types/image-hover';
import BannerCarousel from '../components/molecules/banner-carousel/banner-carousel';
import { BannerWithCarouselModel } from '../types/banner-with-carousel';
import { brandColors } from '../constants/colors';
import SectionContainer from '../components/atoms/section-container/section-container';
import MarginSpacing from '../components/atoms/margin-spacing/margin-spacing';
import { getBrandPageContentsByBrand } from '../services/products';
import { SUBZERO_SLUG, WOLF_SLUG } from '../constants/brands';
import { ProductCategoryModel } from '../interface/products';

type IndexProps = {
  carouselData: BannerWithCarouselModel[];
  imageHoverContent: ImageHoverType[];
  subzeroCategoryCardsContent: ProductCategoryModel[];
  subzeroCategoryCardsHeaderContent: ContentType;
  wolfCategoryCardsContent: ProductCategoryModel[];
  wolfCategoryCardsHeaderContent: ContentType;
};

export function Index(props: IndexProps) {
  const {
    carouselData,
    imageHoverContent,
    subzeroCategoryCardsContent,
    subzeroCategoryCardsHeaderContent,
    wolfCategoryCardsContent,
    wolfCategoryCardsHeaderContent
  } = props;
  const [lettuceContent, pastaContent, steakContent, pastaContentWolf] =
    imageHoverContent;
  const { SUBZERO_HEX, WOLF_HEX } = brandColors;

  return (
    <>
      <BannerCarousel carouselData={carouselData} />
      <ImageHoverDivider imageHoverContent={[pastaContent, lettuceContent]} />
      <SectionContainer paddingX="px-0 md:px-4">
        <CategoryCardsHeader {...subzeroCategoryCardsHeaderContent} />
        <MarginSpacing margin="my-8" />
        <CategoryCards
          categoryCardsContent={subzeroCategoryCardsContent}
          paginationColor={SUBZERO_HEX}
        />
      </SectionContainer>
      <MarginSpacing margin="my-4 md:my-20" />
      <ImageHoverDivider imageHoverContent={[steakContent, pastaContentWolf]} />
      <SectionContainer paddingX="px-0 md:px-4">
        <CategoryCardsHeader {...wolfCategoryCardsHeaderContent} />
        <MarginSpacing margin="my-8" />
        <CategoryCards
          categoryCardsContent={wolfCategoryCardsContent}
          paginationColor={WOLF_HEX}
        />
        <MarginSpacing margin="mb-8 md:mb-20" />
      </SectionContainer>
    </>
  );
}

export async function getStaticProps() {
  const carouselData = await getHomepageCarouselContent();
  const imageHoverContent = await getImageHoverContent();
  const subzeroBrandContent = await getBrandPageContentsByBrand(SUBZERO_SLUG);
  const wolfBrandContent = await getBrandPageContentsByBrand(WOLF_SLUG);
  const subzeroCategoryCardsHeaderContent =
    await getSubzeroCategoryCardsHeaderContent();
  const wolfCategoryCardsHeaderContent =
    await getWolfCategoryCardsHeaderContent();

  const subzeroCategoryCardsContent =
    subzeroBrandContent[0].attributes.categories;
  const wolfCategoryCardsContent = wolfBrandContent[0].attributes.categories;

  return {
    props: {
      carouselData,
      imageHoverContent,
      subzeroCategoryCardsContent,
      subzeroCategoryCardsHeaderContent,
      wolfCategoryCardsContent,
      wolfCategoryCardsHeaderContent
    }
  };
}

export default Index;
