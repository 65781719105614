/* eslint-disable-next-line */
type SectionContainerProps = {
  children?: React.ReactNode | React.ReactNodeArray;
  className?: string;
  paddingX?: string;
};

export function SectionContainer(props: SectionContainerProps) {
  const { children, className, paddingX = 'px-4' } = props;

  return (
    <div
      className={`relative h-full w-full max-w-defaultMax mx-auto py-12 ${paddingX} ${className}`}
    >
      {children}
    </div>
  );
}

export default SectionContainer;
